<template>
  <div class="footer">
    <div class="main-content">
      <div class="logo-box">
        <img class="logo" src="../assets/img/home/web-logo.png" alt="" />
        <img class="dr-txt" src="../assets/img/foot/dr-txt.png" alt="" />
      </div>
      <div class="txt-box">
        <h3>联系我们</h3>
        <div class="txt-list">
          <img src="../assets/img/foot/phone.png" alt="" />
          <p>客服电话：400-833-6013</p>
        </div>
        <div class="txt-list">
          <img src="../assets/img/foot/company.png" alt="" />
          <p>运营单位：广州上进文化传播有限公司</p>
        </div>
        <div class="txt-list">
          <img src="../assets/img/foot/address.png" alt="" />
          <p>公司地址：广东省广州市黄埔区奥园广场H6栋17楼</p>
        </div>
      </div>
      <div class="link-box">
        <div class="link-title">友情链接</div>
        <div class="link-des">
          <a target="_blank" href="https://www.xzhengshu.cn/">1+X证书学习网</a>
        </div>
      </div>
      <div class="qr-box">
        <div class="content">
          <img src="../assets/img/foot/weiChat-qr.png" alt="" />
          <span>上进青年微信公众号</span>
        </div>
        <div class="content">
          <img src="../assets/img/foot/weibo-qr.jpg" alt="" />
          <span>探索上进的力量微博话题词 </span>
        </div>
      </div>
    </div>
    <div class="remark-wrap">
      <a class="remark-code" target="_blank" href="https://beian.miit.gov.cn">
        备案号：粤ICP备2022021070号</a
      >
      <a
        class="remark-code"
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202002599"
      >
        <img
          class="icon"
          src="../assets/img/foot/police.png"
          alt=""
        />粤公网安备 44011202002599号</a
      >
    </div>

    <!-- <p class="remark-code">主体： 广州上进青年教育投资有限公司</p> -->
  </div>
</template>

<script lang="ts" setup></script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 100%;
  background-color: #16181a;
  padding-bottom: 20px;
}

// 移动端
@media screen and (max-width: 992px) {
  .main-content {
    padding: 24px 0 12px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo-box {
      .logo {
        width: 150px;
        height: 50px;
      }
      .dr-txt {
        display: none;
      }
    }
    .txt-box {
      margin-top: 50px;
      h3 {
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
        margin-bottom: 32px;
      }
      .txt-list {
        display: flex;
        margin-bottom: 25px;
        padding: 0 80px;
        img {
          width: 18px;
          height: 18px;
        }
        p {
          margin-left: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
      }
    }
    .link-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 50px;
      .link-title {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
        margin-bottom: 32px;
      }
      .link-des {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        a {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 10px;
        }
      }
    }
    .qr-box {
      display: flex;
      align-items: center;
      margin-top: 22px;
      .content {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 126px;
          height: 126px;
        }
        span {
          margin-top: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
      }
    }
  }
  .remark-wrap {
    display: flex;
    margin-top: 19px;
    flex-direction: column;
  }
  .remark-code {
    margin-bottom: 5px;
    text-align: center;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 17px;
    display: block;
    text-decoration: underline;
  }
  .icon {
    width: 17px;
    height: 17px;
    vertical-align: sub;
  }
}
// PC端
@media screen and (min-width: 993px) {
  .main-content {
    padding-top: 57px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .logo-box {
      display: flex;
      flex-direction: column;
      .logo {
        width: 150px;
        height: 50px;
      }
      .dr-txt {
        margin-top: 24px;
        width: 142px;
        height: 44px;
      }
    }
    .txt-box {
      margin-left: 135px;
      h3 {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
        margin-bottom: 32px;
      }
      .txt-list {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        img {
          width: 18px;
          height: 18px;
        }
        p {
          margin-left: 2px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }

    .link-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      .link-title {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 33px;
        margin-bottom: 32px;
      }
      .link-des {
        display: flex;
        flex-direction: column;
        a {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 10px;
        }
      }
    }
    .qr-box {
      display: flex;
      align-items: center;
      .content {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 126px;
          height: 126px;
        }
        span {
          margin-top: 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
      }
    }
  }
  .remark-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 19px;
  }
  .remark-code {
    display: block;
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    text-decoration: underline;
    margin-right: 30px;
  }
  .icon {
    vertical-align: sub;
  }
}
</style>
