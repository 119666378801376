import axios from 'axios';
import md5 from 'js-md5';
import { Base64 } from 'js-base64';
import { Toast } from 'vant';
import { ElMessageBox, ElMessage } from 'element-plus';
import router from '@/router';
const http = axios.create({
  timeout: 1000 * 160,
  // baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false
});

// http request 拦截器
http.interceptors.request.use(
  (config: any) => {
    // 配置请求头
    config.headers['content-Type'] = 'text/plain;charset=utf-8';
    if (!config.url.startsWith('http')) {
      config.url = 'proxy' + config.url;
    }
    // 携带token的拦截
    const authToken = window.localStorage.getItem('authToken');
    authToken && (config.headers.authtoken = authToken);
    const timeStamp = Date.now();
    config.data['timeStamp'] = timeStamp;
    const sign = md5(`${timeStamp}${'7RKb8wIe'}`).toUpperCase();
    config.data['sign'] = sign;

    // 发送时间
    const sendTime = new Date().getTime();
    // 批次调用唯一标识
    const transferId = uuid();
    // 在当次调用本次调用的顺序
    const transferSeq = '1';
    // 当次调用的地址
    const uri = `${document.URL}`;
    // 当次调用的备注
    const title = document.title;

    const frontTrace = JSON.stringify({ sendTime, transferId, transferSeq, uri, title, phoneModel, phoneSys });
    let data: any = {
      header: { appType: '11', frontTrace },
      body: config.data || {}
    };
    if (config.url.includes('/uploadFile')) return config;
    if (config.url.trim().endsWith('/1.0/')) {
      data = Base64.encode(JSON.stringify(data));
    } else {
      data = JSON.stringify(data);
    }
    config.data = data;
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// http response 拦截器
http.interceptors.response.use(
  (response: any) => {
    const { data } = response;

    try {
      responseCode(data, JSON.parse(Base64.decode(response.config.data) || '{}').body || {});
    } catch (e) {
      responseCode(data, {});
    }
    return response.data;
  },
  (error: { response: any; }) => {
    const { response } = error;
    if (response) {
      return Promise.reject(response.data);
    } else {
      Toast('网络连接异常,请稍后再试!');
    }
  }
);

function responseCode(data: any, fromData: any) {
  console.log(data);
  if (data.code === '00') {
    return;
  }
  // 登录时效或者未登录
  const codeArray = ['E00001', 'E00010'];
  if (codeArray.includes(data.code)) {
    localStorage.clear();
    ElMessage.error('请先登录');

    setTimeout(() => {
      router.push({
        path: '/'
      });
    }, 1000);
  } else {
    ElMessage.error(data?.message || '网络连接异常,请稍后再试!');
    // Toast(data?.message || '网络连接异常,请稍后再试!');
  }
}

const transferIdMap = {};
// eslint-disable-next-line no-unused-vars
let phoneModel = '';
// eslint-disable-next-line no-unused-vars
let phoneSys = '';
// 获取手机型号和系统
function getPhoneNameAndSystemName() {
  phoneModel = 'mobile';
  phoneSys = window.navigator.userAgent;
}

// 生成UUID
function uuid() {
  const type = window.event && window.event.type;
  if (type !== 'click') {
    const e = document.URL;
    if (transferIdMap[e]) { return transferIdMap[e]; }
  }
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4';
  s[19] = hexDigits.substr((Number(s[19]) & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = '';
  const uuid = s.join('');
  if (type !== 'click') {
    const e = document.URL;
    transferIdMap[e] = uuid;
  }
  return uuid;
}
export default http;
