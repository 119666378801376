import config from '../../_config';

/**
 * 用于个人主页圈子时间格式
 * @param {Strig} val 时间字符串(格式：YYYY-MM-DD HH:mm:ss)
 */
export const timeTransform = (valueTime: any) => {
  if (valueTime) {
    valueTime = valueTime.replace(/-/g, '/');
    valueTime = new Date(valueTime);
    valueTime = valueTime.getTime();
    const newData = Number(Date.parse(new Date().toString()));
    const diffTime = Math.abs(newData - valueTime);
    if (diffTime > 7 * 24 * 3600 * 1000) {
      var date: any = new Date(valueTime);
      const y = date.getFullYear();
      let m: number | string = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      let d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      let second = date.getSeconds();
      minute = minute > 10 ? minute : '0' + minute;
      second = second < 10 ? ('0' + second) : second;
      return m + '-' + d + ' ' + h + ':' + minute;
    } else if (diffTime < 7 * 24 * 3600 * 1000 && valueTime < new Date(new Date(new Date().toLocaleDateString()).getTime())) {
      let week;
      var date: any = new Date(valueTime);
      if (date.getDay() === 0) week = '星期日';

      if (date.getDay() === 1) week = '星期一';

      if (date.getDay() === 2) week = '星期二';

      if (date.getDay() === 3) week = '星期三';

      if (date.getDay() === 4) week = '星期四';

      if (date.getDay() === 5) week = '星期五';

      if (date.getDay() === 6) week = '星期六';
      var h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      minute = minute > 10 ? minute : '0' + minute;
      return `${week} ${h}:${minute}`;
    } else if (diffTime < 24 * 3600 * 1000 && diffTime > 3600 * 1000) {
      var date: any = new Date(valueTime);
      var h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      minute = minute > 10 ? minute : '0' + minute;
      return `${h}:${minute}`;
    } else if (diffTime < 300 * 1000 && diffTime >= 0) {
      return '刚刚';
    } else if (diffTime < 3600 * 1000 && diffTime > 0) {
      const dayNum = Math.floor(diffTime / (60 * 1000));
      return dayNum + '分钟前';
    }
  }
};

/**
 * 转换时间格式变成没有秒
 * @param {Strig} val 时间字符串(格式：YYYY-MM-DD HH:mm:ss)
 */
export const timeTransSecond = (time: string, format = 'YYYY-MM-DD') => {
  if (!time) return '';
  const arr = time.split(' ')[1].split(':');
  const arrDay = time.split(' ')[0].split('-');
  const MM = arrDay[1];
  const DD = arrDay[2];
  const m = arr[1];
  const h = arr[0];
  const d = time.split(' ')[0];
  if (format === 'MM.DD') {
    return `${MM}.${DD}`;
  }
  return `${d} ${h}:${m}`;
};

/**
 * 图片地址加前缀
 * 带默认图片
 * */
export const fullUrl = (url: string) => {
  console.log(url);
  if (!url || url === 'null') return require('../../assets/img/ic_mine_head_man.png');
  if (url.indexOf('http') !== -1) return url;
  return `${config.articleUrl}${url}`;
};

export const imgUrl = (url: string) => {
  if (!url) return '';
  if (url.indexOf('http') !== -1) return url;
  return `${config.articleUrl}${url}`;
};
/**
 * 隐藏姓名第一位以外的,保留三位
 * */
export const hideName = (name:string) => {
  if (!name) return name;
  return name.replace(/^(.).+/, '$1**');
};
const dict = window.localStorage.getItem('dict') || '';

const dictJson = dict ? JSON.parse(dict) : {};

export const findDict = (dictValue: any, dictId: string | number, defaultValue?: string) => {
  return ((dictJson[dictId] || []).find((item: { dictValue: string; }) => item.dictValue === dictValue) || {}).dictName || defaultValue || '';
};

export const len = (s: string) => {
  s = String(s);
  return s.length + (s.match(/[^\x00-\xff]/g) || '').length;// 加上匹配到的全角字符长度
};

/**
 * 时间戳转换时间日期
 * */
function add0(m: string | number) { return m < 10 ? '0' + m : m; }
export const format = (shijianchuo?: string | number | Date, format?: string) => {
  // shijianchuo是整数，否则要parseInt转换
  const time = new Date(shijianchuo || '');
  const y = time.getFullYear();
  const m = time.getMonth() + 1;
  const d = time.getDate();
  const h = time.getHours();
  const mm = time.getMinutes();
  const s = time.getSeconds();
  if (format === 'YYYY年MM月DD日 hh:mm') {
    return y + '年' + add0(m) + '月' + add0(d) + '日 ' + add0(h) + ':' + add0(mm);
  }
  return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
};

