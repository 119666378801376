import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/css/base.less';
import VConsole from 'vconsole';

if (process.env.VUE_APP_ENV === 'test' || process.env.VUE_APP_ENV === 'development') {
  new VConsole();
}
const mountApp = createApp(App);
mountApp.use(store).use(router).use(Vant).use(ElementPlus);
mountApp.mount('#app');
