
let articleUrl, signPath, postDetailUrl;
// postDetailUrl为上进青年h5专用

if (process.env.VUE_APP_ENV === 'production') { // 正式环境
  articleUrl = 'https://yzims.oss-cn-shenzhen.aliyuncs.com/';
  // articleUrl = 'https://yzimstest.oss-cn-shenzhen.aliyuncs.com/';
  signPath = 'http://yzimstemp.oss-cn-shenzhen.aliyuncs.com/';

  postDetailUrl = '//sjqn-geteway.shangjinqingnian.com/'; // 正式生成环境
} else if (process.env.VUE_APP_ENV === 'test') { // 测试环境
  articleUrl = 'https://yzims.oss-cn-shenzhen.aliyuncs.com/';
  // articleUrl = 'https://yzimstest.oss-cn-shenzhen.aliyuncs.com/';
  signPath = 'http://yzimstemp.oss-cn-shenzhen.aliyuncs.com/';

  postDetailUrl = '//shangjinqingnian188.yzwill.cn/'; // 188测试环境
} else if (process.env.VUE_APP_ENV === 'development') { // 本地开发
  articleUrl = 'https://yzims.oss-cn-shenzhen.aliyuncs.com/';
  // articleUrl = 'https://yzimstest.oss-cn-shenzhen.aliyuncs/.com/';
  signPath = 'http://yzimstemp.oss-cn-shenzhen.aliyuncs.com/';

  postDetailUrl = 'http://10.0.2.18:10700/';
} else {
  articleUrl = 'https://yzims.oss-cn-shenzhen.aliyuncs.com/';

  // articleUrl = 'https://yzimstest.oss-cn-shenzhen.aliyuncs.com/';
  signPath = 'http://yzimstemp.oss-cn-shenzhen.aliyuncs.com/';

  postDetailUrl = 'http://shangjinqingnian188.yzwill.cn/';
}
export default {
  articleUrl, signPath, postDetailUrl
};
